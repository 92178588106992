import React, { useState, useEffect } from "react"
import SocialLinks from "./SocialLinks"

const Footer = ({}) => {
  const openCalendly = slug => {
    window.Calendly.initPopupWidget({
      url: `https://calendly.com/codingchamps/${slug}`,
    })
  }

  return (
    <>
      <footer className="bg-li py-5">
        <div className="container py-xl-5 py-lg-3">
          <div className="subscribe mx-auto">
            <h2
            // className="tittle text-center font-weight-bold"
            // data-sal="slide-up"
            // data-sal-delay="200"
            // data-sal-duration="1500"
            // data-sal-easing="ease-out-back"
            >
              Got <span className="title-span">Questions?</span>
            </h2>
            <p
            // className="sub-tittle text-center mb-sm-3"
            // data-sal="slide-up"
            // data-sal-delay="200"
            // data-sal-duration="1500"
            // data-sal-easing="ease-out-back"
            >
              We’ve got answers—big or small! Reach out anytime.
            </p>
            <div className="center">
              <a
                onClick={e => openCalendly("")}
                rel="noopener noreferrer"
                className="btn button-orange mb-3 read-more text-white"
              >
                Grab Your FREE Trial Class Today!
              </a>
            </div>
            <SocialLinks />
            {/* <div
              data-sal="slide-up"
              data-sal-delay="200"
              data-sal-duration="1500"
              data-sal-easing="ease-out-back"
              style={{
                display: "flex",
                justifyContent: "center",
              }}
            >
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  width: "40%",
                }}
              >
                <a href="tel:+1-437-421-1707">
                  <div className="icon-effect-w3">
                    <span class="fa solid fa-phone"></span>
                  </div>
                </a>

                <a href="mailto:hello@codingchamps.ca">
                  <div
                    className="icon-effect-w3"
                    style={{ marginLeft: "12px" }}
                  >
                    <span className="fa fa-envelope"></span>
                  </div>
                </a>

                <a href="https://www.facebook.com/codingchamps.ca">
                  <div
                    className="icon-effect-w3"
                    style={{ marginLeft: "12px" }}
                  >
                    <span className="fab fa-facebook"></span>
                  </div>
                </a>
              </div>
            </div> */}
          </div>
        </div>
      </footer>

      <div className="copy-bottom bg-li py-4 border-top">
        <div className="container-fluid">
          <div className="d-md-flex px-md-3 position-relative text-center">
            {/* <div className="social-icons-footer mb-md-0 mb-3">
              <ul className="list-unstyled">
                <li>
                  <a href="#">
                    <span className="fab fa-facebook"></span>
                  </a>
                </li>
                <li>
                  <a href="#">
                    <span className="fab fa-twitter"></span>
                  </a>
                </li>
                <li>
                  <a href="#">
                    <span className="fab fa-google-plus"></span>
                  </a>
                </li>
                <li>
                  <a href="#">
                    <span className="fab fa-instagram"></span>
                  </a>
                </li>
              </ul>
            </div> */}

            <div className="copy_right mx-md-auto mb-md-0 mb-3">
              <p className="text-bl let">
                © 2022 All rights reserved by <b>Coding Champs</b>
                {/* <a href="http://w3layouts.com/" target="_blank">
                  W3layouts
                </a> */}
              </p>
            </div>

            {/* <a href="#home" className="move-top text-center">
              <i className="fa fa-level-up-alt" aria-hidden="true"></i>
            </a> */}
          </div>
        </div>
      </div>
    </>
  )
}

export default Footer
