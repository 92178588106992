// SocialLinks.jsx
import React from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faPhone, faEnvelope } from "@fortawesome/free-solid-svg-icons"
import {
  faFacebook,
  faLinkedin,
  faTiktok,
  faInstagram,
} from "@fortawesome/free-brands-svg-icons"
import styles from "../../static/css/socialLinks.module.css"

const SocialLinks = () => {
  const socialLinks = [
    {
      href: "tel:+1-437-421-1707",
      icon: faPhone,
      ariaLabel: "Call us",
    },
    {
      href: "mailto:hello@codingchamps.ca",
      icon: faEnvelope,
      ariaLabel: "Email us",
    },
    {
      href: "https://www.facebook.com/codingchamps.ca",
      icon: faFacebook,
      ariaLabel: "Visit our Facebook",
    },
    {
      href: "https://ca.linkedin.com/company/codingchamps",
      icon: faLinkedin,
      ariaLabel: "Visit our LinkedIn",
    },
    {
      href: "https://www.tiktok.com/@coding.champs/",
      icon: faTiktok,
      ariaLabel: "Follow us on TikTok",
    },
    {
      href: "https://www.instagram.com/codingchamps.ca",
      icon: faInstagram,
      ariaLabel: "Follow us on Instagram",
    },
  ]

  return (
    <div
      data-sal="slide-up"
      data-sal-delay="200"
      data-sal-duration="1500"
      data-sal-easing="ease-out-back"
      className={styles.socialLinksContainer}
    >
      <div className={styles.socialLinksWrapper}>
        {socialLinks.map((link, index) => (
          <a
            key={index}
            href={link.href}
            target={link.href.startsWith("http") ? "_blank" : "_self"}
            rel={link.href.startsWith("http") ? "noopener noreferrer" : ""}
            aria-label={link.ariaLabel}
            className={styles.linkStyle}
          >
            <div className={styles.iconContainer}>
              <FontAwesomeIcon icon={link.icon} className={styles.socialIcon} />
            </div>
          </a>
        ))}
      </div>
    </div>
  )
}

export default SocialLinks
